import {
  HttpErrorResponse,
  HttpEventType,
  HttpInterceptorFn,
  HttpRequest,
  HttpResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { catchError, map, throwError } from 'rxjs';
import { Store } from '@ngxs/store';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import {
  convertPropsToCamelCase,
  convertPropsToSnakeCase,
  replaceEmptyStringsWithNull,
} from '@shared/utils/common';
import { AuthState } from '@store/auth/auth.state';
import { Logout } from '@store/auth/auth.actions';
import { FORBIDDEN_PAGE, LOGIN_PAGE } from './route-names';

function isAllowedBodyType(event: HttpResponse<unknown>) {
  return ![Blob, ArrayBuffer].some(type => event.body instanceof type);
}

function isAllowedToken(req: HttpRequest<unknown>) {
  return !req.url.includes('web.declarant.by');
}

function isAllowedUrl(event: HttpResponse<unknown>) {
  const except = [
    '/api/nsi/common/getLastUpdated',
    '/declaration/flk',
    '/declaration/validatePayments',
    'api/nsi/conformityCertificate/getByNumbers',
  ];
  return !except.some(u => event?.url?.includes(u));
}

function isUnauthorized(err: HttpErrorResponse) {
  return HttpStatusCode.Unauthorized === err.status;
}

function isForbidden(err: HttpErrorResponse) {
  return HttpStatusCode.Forbidden === err.status;
}

export const httpInterceptor: HttpInterceptorFn = (req, next) => {
  const store = inject(Store);
  const router = inject(Router);
  const token = store.selectSnapshot(AuthState.getToken);
  const authorizedRequest = req.clone({
    headers: isAllowedToken(req)
      ? req.headers.set('Authorization', `Bearer ${token}`)
      : req.headers,
    body:
      req.body instanceof FormData
        ? req.body
        : replaceEmptyStringsWithNull(
            convertPropsToSnakeCase(req.body as Record<string, any>),
          ),
  });
  return next(authorizedRequest).pipe(
    map(event => {
      if (
        event.type === HttpEventType.Response &&
        isAllowedUrl(event) &&
        isAllowedBodyType(event)
      ) {
        return event.clone({ body: convertPropsToCamelCase(event.body) });
      }
      return event;
    }),
    catchError((err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (isUnauthorized(err)) {
          store.dispatch(new Logout());
          router.navigate([LOGIN_PAGE]);
        }
        if (isForbidden(err)) {
          router.navigate([FORBIDDEN_PAGE]);
        }
      }
      return throwError(() => err);
    }),
  );
};
