import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TuiAlertModule, TuiNotificationModule } from '@taiga-ui/core';

@Component({
  selector: 'app-forbidden-page',
  standalone: true,
  imports: [TuiAlertModule, TuiNotificationModule],
  templateUrl: './forbidden-page.component.html',
  styleUrl: './forbidden-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForbiddenPageComponent {}
